<!-- Action Panel -->
<div class="row">
   <div class="col-12">
      <nav class="navbar navbar-expand-lg navbar-light">
         <!-- Search form -->
         <form class="container-fluid ps-0 pe-lg-0">
            <div class="row">
               <!-- Search input box -->
               <div class="col-11 col-lg-6 col-xl-4 py-2 py-lg-0 pe-0">
                  <input class="form-control border-0" list="datalistOptions" #box (keyup)="onValue(box.value)"
                     (keyup.enter)="onEnter(box.value)" id="exampleDataList" placeholder="Buscar {{titulo}}" size="80"
                     style="background-color:#CEDADF; font-size: 2.3vh; font-family: 'Roboto Condensed'">
                  <datalist id="datalistOptions">
                     <option *ngFor="let report of reportList" value="{{report.title}}">
                  </datalist>
               </div>
               <!-- Search button -->
               <div class="col-1 col-lg-1 col-xl-1 py-2 py-lg-0 px-0">
                  <button type="button" (click)="searchButton()" class="btn btn-secondary btn1 mx-3 mx-lg-3"
                     style="padding: 0.7vh 2vh;">
                     <i class="lupa fas fa-search fa-1x "></i>
                  </button>
               </div>
            </div>
         </form>
      </nav>
      <!-- Filters -->
      <div class="row mb-3">
         <!-- Category filter -->
         <div class="col-10 col-sm-10 col-md-6 col-lg-8 col-xl-3 pt-lg-4 pt-xl-0">
            <select id="raza" type="text" (change)="categoryFilter($event)"
               class="form-select style btn1 me-auto my-2 my-lg-0 d-block">
               <option selected disabled value>Buscar por categoría</option>
               <option *ngFor="let category of categoryList" value={{category._id}}>{{
                  category.name[0].toUpperCase() + category.name.slice(1) }}</option>
               <option value>Todas Las Categorías</option>
            </select>
         </div>
         <!-- Status filter -->
         <div class="col-10 col-sm-10 col-md-6 col-lg-8 col-xl-3 pt-lg-4 pt-xl-0">
            <select id="raza" type="text" (change)="statusReviewFilter($event)"
               class="form-select style btn1 me-auto my-2 my-lg-0 d-block">
               <option selected disabled value id="1">Revision</option>
               <option *ngFor="let status of withReviewStatusList" value={{status.data}}>{{
                  status.name.toUpperCase()}}</option>
               <option value={{null}}>Todos</option>
            </select>
         </div>
      </div>
   </div>
</div>

<div class="row">
   <!-- Map -->
   <div [ngClass]="'col-sm-12'">
      <agm-map [styles]='[
          {
             elementType : "labels.icon",
             stylers : [{
                visibility : "off"
             }]
          }]' [latitude]="lat" [longitude]="lng" [zoom]="zoom" [mapTypeId]="mapTypeId" [fitBounds]="fitBounds"
         (zoomChange)="changeMapZoom($event)" (centerChange)="cambioDeCentro($event)" (mapClick)="mapClicked($event)">
         <div *ngIf="isEmpty()">
            <agm-marker [latitude]="lat" [longitude]="lng" [agmFitBounds]="fitBounds" [visible]="false">
            </agm-marker>
         </div>
         <agm-marker *ngFor="let report of reportList | reportFilter: filterReport"
            [latitude]="report.location.latitude" [longitude]="report.location.longitude" [iconUrl]="icon"
            [agmFitBounds]="fitBounds" (markerClick)="markerClicked($event)">
         </agm-marker>
      </agm-map>
   </div>

   <!-- Complaint info panel -->
   <!-- <ng-template [ngIf]="clickMark">
      <div class="col-12 col-xl-5 mt-5 mt-xl-1">
         Image
         <div class="row m-0 mb-3">
            <div class="col text-center">
               <img [src]="reporte.imageUrl" alt="" class="img-fluid img-thumbnail shadow-sm" style="max-height: 15vh;"
                  data-toggle="modal" data-target="#exampleModal2">
            </div>
         </div>
         <div class="row">
            Title
            <h4 class="mb-0" style="color: #000000; font-size: 2.8vh; font-weight: bold;">{{reporte.title | uppercase}}
            </h4>
            Date
            <p class="mb-5" class="lead" style="color: #96AC78; font-size: 2vh; font-weight: normal;">Fecha:
               {{reporte.createdAt | date: 'd/M/yy h:mm:ss'}}</p>
         </div>
         Description
         <div class="row">
            <h5 class="text-uppercase mt-1 mb-0" style="color: #000000; font-size:2.2vh; font-weight: bold;">Descripción
               del Reporte</h5>
            <p class=" mb-1" style="color: #676767; font-size: 2.2vh;">{{reporte.description}}</p>
         </div>
         Validations
         <div class="row mt-4 justify-content-center">
            <div class="col-10 col-sm-6">
               <div class="card bg-brand" style="border-radius: 20px;">
                  <div class="card-body py-1 text-center">
                     <h5 class="text-white" style="font-weight: normal; font-size: 2.4vh; margin-bottom: 0.8vh;">
                        Validaciones</h5>
                     <h4 class="lead text-white text-brand-secondary mb-0" style="font-size: 2.4vh; margin-bottom:1vh;">
                        {{reporte.checks}}</h4>
                  </div>
               </div>
            </div>
         </div>

         Comments
         <div class="row mt-4">
            <div class="col-12">
               <h4 class="text-uppercase mb-0" style="color: #000000; font-size:2.2vh; font-weight: 700;">Comentarios
               </h4>
               <hr class="py-0">
               <div class="row" style="max-height: 15vh; overflow-y: scroll;">
                  <div class="col">
                     <div class="card shadow-sm mb-2" style="border-radius: 30px;" *ngFor="let comment of comments">
                        <div class="card-body py-2">
                           <h5 class="card-title text-brand mb-0" style="font-size: 1.9vh;">{{comment.user.names |
                              uppercase}}</h5>
                           <p class="card-text" style="color: #000000; font-size: 2vh;">{{comment.description |
                              capitalize}}</p>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="row mt-4">
                  <div class="card" style="border:none">
                     <form [formGroup]="checkoutForm" #commentForm="ngForm" (ngSubmit)="saveComment($event)">
                        <div class="pt-3" style="font-weight: 600;">
                           <h4 style="font-size:2.2vh">ESCRIBIR NUEVO COMENTARIO</h4>
                        </div>
                        <div class="card-body">
                           <textarea style="font-size:2.2vh" type="text" class="form-control"
                              formControlName="comentario"></textarea>
                           <small id="comment" class="form-text text-muted"
                              *ngIf=" commentForm.submitted && controlIsRequired('comentario')"> Requerido </small>
                           <div class="row text-end">
                              <div class="col">
                                 <button class="btn bg-brand text-white mt-3 col-8 col-sm-4" type="submit"
                                    style="font-size: 2.4vh; border-radius: 50px; padding-bottom: 5px;">COMENTAR</button>
                              </div>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </ng-template> -->
</div>