<section id="table-dogs">
    <div class="row">
        <div class="col-12">
            <nav class="navbar navbar-expand-lg navbar-light">
                <form class="container-fluid ps-0 pe-lg-0">
                    <div class="row">
                        <div class="col-11 col-lg-6 col-xl-4 py-2 py-lg-0 pe-0">
                            <input class="form-control border-0" list="datalistOptions" #box
                                (keyup)="onValue(box.value)" (keyup.enter)="onEnter(box.value)" id="exampleDataList"
                                placeholder="Buscar reportes" size="80"
                                style="background-color:#CEDADF; font-size: 2.3vh; font-family: 'Roboto Condensed'">
                            <datalist id="datalistOptions">
                                <option *ngFor="let report of reportsSlice" value="{{report.title}}">
                            </datalist>
                        </div>
                        <div class="col-1 col-lg-1 col-xl-1 py-2 py-lg-0 px-0">
                            <button type="button" (click)="searchButton()" class="btn btn-secondary btn1 mx-3 mx-lg-3"
                                style="padding: 0.7vh 2vh;">
                                <i class="lupa fas fa-search fa-1x "></i>
                            </button>
                        </div>
                    </div>
                </form>
            </nav>
            <div class="row mb-3">
                <div class="col-10 col-sm-10 col-md-6 col-lg-8 col-xl-3 pt-lg-4 pt-xl-0">
                    <select id="raza" type="text" (change)="categoryFilter($event)"
                        class="form-select style btn1 me-auto my-2 my-lg-0 d-block">
                        <option selected disabled value>Buscar por categoría</option>
                        <option *ngFor="let category of categoryList" value={{category._id}}>{{
                            category.name[0].toUpperCase() + category.name.slice(1) }}</option>
                        <option value>Todas Las Categorías</option>
                    </select>
                </div>
                <div class="col-10 col-sm-10 col-md-6 col-lg-8 col-xl-3 pt-lg-4 pt-xl-0">
                    <select id="raza" type="text" (change)="statusReviewFilter($event)"
                        class="form-select style btn1 me-auto my-2 my-lg-0 d-block">
                        <option selected disabled value id="1">Revision</option>
                        <option *ngFor="let status of withReviewStatusList" value={{status.data}}>{{
                            status.name.toUpperCase()}}</option>
                        <option value={{null}}>Todos</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="loader; else showLoader" class="container-fluid px-0">
        <div class="row">

            <!-- class="card mx-2 mb-3 col-xs-12 col-sm-6 col-md card-width text-start" -->

            <ng-container *ngFor="let report of reportsSlice | reportFilter: filterReport">
                <div [routerLink]="['/institution/report', report._id]" class="hover card mx-1 mx-xl-2 mb-3 card-width">
                    <div class="row g-0">
                        <div class="col-4 align-self-center text-center">
                            <img [src]="report.imageUrl" alt="..." class="image-fluid"
                                style="width: 11vh; height: 11vh;">
                        </div>
                        <div class="col-8">
                            <div class="card-body">
                                <h5 class="card-title mb-0" style="color:#4c4c4c">{{report.title}}</h5>
                                <p class="card-text" style="color: #1D3E6A;">{{report.category.name }}</p>
                                <p class="card-text"><small style="color:#4c4c4c" class="text-muted">{{report.ubication
                                        }}</small></p>
                                <p class="card-text"><small style="color:#4c4c4c"
                                        class="text-muted">{{report.updatedAt | date :'dd-MM-yyyy HH:mm'}}</small></p>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>


        </div>
    </div>

    <nav class="navbar navbar-expand-lg navbar-light ">
        <div class="container-fluid ps-0">
            <div style="font-size: 2.3vh;" class="ms-auto p-2 bd-highlight nextPage">
                <span class="pe-2"> Ítems por página:</span>
                <select id="page" type="number" (change)="sizePage($event)" class="btn btn1 btn-sm">
                    <option selected>9</option>
                    <option type="number">6</option>
                    <option>3</option>
                </select>
            </div>
            <div class="p-0 p-lg-2">
                <button id="prevButton" type="button" (click)="prevPage()" class="btn btn1 rounded-circle">
                    <i style="padding: 0.2vh;" class="fas fa-angle-left"></i>
                </button> <span style="font-size: 2.3vh;"> {{page}} - {{totalPages}} de {{totalPages}} </span>
                <button id="nextButton" type="button" (click)="nextPage()" class="btn  btn1 rounded-circle">
                    <i style="padding: 0.2vh;" class="fas fa-angle-right"></i>
                </button>
            </div>
        </div>
    </nav>
    <ng-template #showLoader>
        <shared-loader></shared-loader>
    </ng-template>
</section>